import settlementOrderLineTypes from '@ameroservices-platform/shared/enums/settlementOrderLineTypes';

const settlementOrderTypes = {
	SALE: 'sale',
	PAYOUT_BANK: 'payoutBank',
	PAYOUT_POS: 'payoutPos',
	CORRECTION: 'correction',
	PAYMENT_CUSTOMER_ACCOUNT: 'paymentCustomerAccount'
};

export const settlementOrderTypesTranslate = {
	[settlementOrderTypes.SALE]: 'Salg',
	[settlementOrderTypes.PAYOUT_BANK]: 'Udbetaling bank',
	[settlementOrderTypes.PAYOUT_POS]: 'Udbetaling POS',
	[settlementOrderTypes.CORRECTION]: 'Korrektion',
	[settlementOrderTypes.PAYMENT_CUSTOMER_ACCOUNT]: 'Betaling via standholder konto'
};

export const settlementOrderLineTypesTranslate = {
	[settlementOrderTypes.SALE]: {
		[settlementOrderLineTypes.DEPOSIT]: 'Salg',
		[settlementOrderLineTypes.WITHDRAWAL]: 'Retur'
	},
	[settlementOrderTypes.PAYOUT_BANK]: {
		[settlementOrderLineTypes.DEPOSIT]: 'Indbetaling bank',
		[settlementOrderLineTypes.WITHDRAWAL]: 'Anmodet om bank udbetaling'
	},
	[settlementOrderTypes.PAYOUT_POS]: {
		[settlementOrderLineTypes.DEPOSIT]: 'Indbetaling kasse',
		[settlementOrderLineTypes.WITHDRAWAL]: 'Udbetaling kasse'
	},
	[settlementOrderTypes.CORRECTION]: {
		[settlementOrderLineTypes.DEPOSIT]: 'Indbetaling korrektion',
		[settlementOrderLineTypes.WITHDRAWAL]: 'Udbetaling korrektion'
	},
	[settlementOrderTypes.PAYMENT_CUSTOMER_ACCOUNT]: {
		[settlementOrderLineTypes.DEPOSIT]: 'Betaling via standholder konto',
		[settlementOrderLineTypes.WITHDRAWAL]: 'Betaling via standholder konto'
	}
};

export default settlementOrderTypes;
